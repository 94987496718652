import { useCallback } from 'react';

export function useScrollToSection() {
  const scrollToSection = useCallback((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      document.querySelectorAll('.highlight').forEach((el) => {
        el.classList.remove('highlight');
      });

      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offset = 112;
      const targetPosition = elementPosition - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });

      element.classList.add('highlight');

      setTimeout(() => {
        element.classList.remove('highlight');
      }, 3000);
    }
  }, []);

  return scrollToSection;
}
