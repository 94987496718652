import { useContext } from 'react';
import { ToastContext } from '../contexts/ToastContext';

export const useToast = () => {
  const context = useContext(ToastContext);

  if (typeof window === 'undefined') {
    return { addToast: () => {} };
  }

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
