import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Layout from './layout';
import HeroTitle from './HeroTitle';
import BlogHighlights from './BlogHighlights';
import SubscribeBlock from './SubscribeBlock';
import { CommonContext } from '../context';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlogSharing from './BlogSharing';
import TableOfContent from './TableOfContent';
import BackToTop from './BackToTop';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { CalenderIcon, ClockIcon } from '../custom-icons';
import { getHeadings } from '../utils';
import BlogCategoryBanner from './BlogCategoryBanner';

const BLOG_CONSTANTS = {
  LAYOUT: {
    MAX_WIDTH: 'lg:max-w-[968px]',
    PADDING: 'p-6 pt-8 lg:pt-10 lg:p-0',
  },
  GRID: {
    TOC_LAYOUT: 'grid-cols-1 gap-8 lg:grid-cols-[288px_1fr]',
    NO_TOC_LAYOUT: 'grid-cols-1 gap-8',
  },
};

const CategoryButton = ({ slug, name }) => (
  <Link to={`/blog/category/${slug}`} passHref>
    <motion.button
      className="px-5 py-1 rounded-lg bg-neutral-2 text-neutral-7 hover:shadow"
      whileHover={{ scale: 1.075 }}
      whileTap={{ scale: 0.9 }}
    >
      {name.charAt(0).toUpperCase() + name.slice(1)}
    </motion.button>
  </Link>
);

CategoryButton.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const ReadTimeDisplay = ({ timeToRead }) => {
  if (!timeToRead) return null;

  return (
    <div className="flex items-center justify-start gap-2 text-sm md:text-base">
      <ClockIcon className="text-neutral-7" />
      <p>{timeToRead} min read</p>
    </div>
  );
};

ReadTimeDisplay.propTypes = {
  timeToRead: PropTypes.number,
};

const PostMetadata = ({ categories, timeToRead, modified }) => (
  <div className="flex flex-wrap items-center justify-start gap-4 md:gap-6">
    {categories.nodes.map((cat) => (
      <CategoryButton key={cat.slug} {...cat} />
    ))}
    <ReadTimeDisplay timeToRead={timeToRead} />
    <div className="items-center justify-start hidden gap-2 text-sm sm:flex md:text-base">
      <CalenderIcon className="text-neutral-7" />
      <p>{modified}</p>
    </div>
  </div>
);

PostMetadata.propTypes = {
  categories: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  timeToRead: PropTypes.number,
  modified: PropTypes.string.isRequired,
};

const PostContent = ({ content, tocHeadings, canonicalUrl }) => {
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  // SSR mode
  if (!isHydrated)
    return (
      <div className="sr-only" dangerouslySetInnerHTML={{ __html: content }} />
    );

  return (
    <div className="relative">
      <div
        className={`grid ${
          tocHeadings.length > 0
            ? BLOG_CONSTANTS.GRID.TOC_LAYOUT
            : BLOG_CONSTANTS.GRID.NO_TOC_LAYOUT
        }`}
      >
        {tocHeadings.length > 0 && (
          <div className="hidden lg:block">
            <div className="sticky top-28">
              <TableOfContent tocHeadings={tocHeadings} />
              <BlogSharing canonicalUrl={canonicalUrl} />
            </div>
          </div>
        )}
        <div
          className={`w-full markdown blog md:p-0 ${
            tocHeadings.length === 0 ? 'lg:max-w-[968px] mx-auto' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

PostContent.propTypes = {
  content: PropTypes.string.isRequired,
  tocHeadings: PropTypes.array.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
};

const MobileSharing = ({ tocHeadings, canonicalUrl }) => {
  return (
    <div className={tocHeadings.length <= 0 ? '' : 'block md:hidden'}>
      <hr className="w-full my-6 text-neutral-2" />
      <div className="flex flex-col items-center justify-center gap-4">
        <p className="text-xl font-semiBold">Share the Knowledge</p>
        <BlogSharing canonicalUrl={canonicalUrl} />
      </div>
    </div>
  );
};

MobileSharing.propTypes = {
  tocHeadings: PropTypes.array.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
};

const BlogSinglePost = ({ data, location }) => {
  const {
    title,
    featuredImage,
    content,
    modified,
    seo,
    categories,
    postMetadata,
  } = data.wpPost;
  const featuredBlogs = data.allFeaturedWpPost.nodes;
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname;

  const [tocHeadings, setTocHeadings] = useState([]);

  useEffect(() => {
    if (content) setTocHeadings(getHeadings(content));
  }, [content]);

  return (
    <CommonContext.Provider value={{ location }}>
      <Layout
        title={title}
        description={seo.description}
        keywords={seo.focusKeywords || []}
        image={featuredImage.node.mediaItemUrl}
        type={seo.openGraph.type}
        additionalData={seo.openGraph.slackEnhancedData}
        location={location}
      >
        <section
          className={`w-full ${BLOG_CONSTANTS.LAYOUT.MAX_WIDTH} ${BLOG_CONSTANTS.LAYOUT.PADDING} m-auto`}
        >
          <PostMetadata
            categories={categories}
            timeToRead={postMetadata?.timeToRead}
            modified={modified}
          />
          <div>
            <HeroTitle
              text={title}
              className="max-w-full my-5 text-3xl font-bold text-start leading-10 md:text-5xl md:leading-[60px] md:my-6"
            />
          </div>

          <div>
            <div
              className="w-full text-sm leading-6 md:leading-7 md:text-base md:p-0 text-neutral-7"
              dangerouslySetInnerHTML={{ __html: postMetadata.summary }}
            />
          </div>
          <div className="block mt-5 lg:hidden">
            <TableOfContent tocHeadings={tocHeadings} />
          </div>
          <div className="my-10">
            <GatsbyImage
              alt={featuredImage.node.altText}
              image={featuredImage.node.gatsbyImage}
              className="w-full"
            />
          </div>
          <PostContent
            content={content}
            tocHeadings={tocHeadings}
            canonicalUrl={canonicalUrl}
          />
          <MobileSharing
            tocHeadings={tocHeadings}
            canonicalUrl={canonicalUrl}
          />
        </section>
        <div className="w-full max-w-6xl px-4 m-auto my-8 mt-16 bg-neutral md:mt-24">
          {categories.nodes.map((category) => {
            if (category?.categoryBannerImages.categoryBannerImage === null)
              return null;
            return (
              <BlogCategoryBanner
                key={category.slug}
                {...category.categoryBannerImages}
              />
            );
          })}
        </div>
        <BlogHighlights
          title="Explore More Inspiring Reads"
          count={3}
          featuredBlogs={featuredBlogs}
        />
        <SubscribeBlock />
        <BackToTop />
      </Layout>
    </CommonContext.Provider>
  );
};

BlogSinglePost.propTypes = {
  data: PropTypes.shape({
    wpPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          mediaItemUrl: PropTypes.string.isRequired,
          altText: PropTypes.string.isRequired,
          gatsbyImage: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
      content: PropTypes.string.isRequired,
      modified: PropTypes.string.isRequired,
      seo: PropTypes.shape({
        description: PropTypes.string.isRequired,
        focusKeywords: PropTypes.array,
        openGraph: PropTypes.shape({
          type: PropTypes.string.isRequired,
          slackEnhancedData: PropTypes.object,
        }).isRequired,
      }).isRequired,
      categories: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            categoryBannerImages: PropTypes.object,
          }),
        ).isRequired,
      }).isRequired,
      postMetadata: PropTypes.shape({
        timeToRead: PropTypes.number,
        summary: PropTypes.string,
      }),
    }).isRequired,
    allFeaturedWpPost: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogSinglePost;
