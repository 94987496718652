import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useScrollToSection } from '../hooks/useScrollToSection';
import { useActiveSection } from '../hooks/useActiveSection';
import { ChevronDown } from '../custom-icons';

const headingsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    hashLink: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
).isRequired;

const COMMON_STYLES = {
  container: 'overflow-hidden border border-neutral-2 rounded-2xl bg-neutral',
  heading: 'text-xl font-semibold',
  headingWrapper: 'p-4',
  list: 'divide-y divide-neutral-2',
  button: 'flex w-full px-5 py-4 text-start hover:bg-neutral-1',
};

const ANIMATIONS = {
  accordion: {
    initial: { height: 0 },
    animate: { height: 'auto' },
    exit: { height: 0 },
    transition: { duration: 0.3 },
  },
  chevron: {
    transition: { duration: 0.3 },
  },
  heading: {
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
  indicator: {
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
};

const HeadingItem = ({ item, isActive, onClick }) => (
  <motion.li
    key={item.hashLink}
    initial={false}
    animate={{ fontSize: isActive ? '1.05rem' : '1rem' }}
    transition={ANIMATIONS.heading.transition}
    className="border-t border-neutral-2"
  >
    <button className={COMMON_STYLES.button} onClick={onClick}>
      <motion.span
        initial={false}
        animate={{
          opacity: isActive ? 1 : 0,
          width: isActive ? 'auto' : 0,
          marginRight: isActive ? '0.5rem' : 0,
        }}
        transition={ANIMATIONS.indicator.transition}
      >
        -
      </motion.span>
      <span className={isActive ? 'font-semibold' : ''}>{item.title}</span>
    </button>
  </motion.li>
);

HeadingItem.propTypes = {
  item: PropTypes.shape({
    hashLink: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const AccordionVersion = ({
  headings,
  activeSection,
  scrollToSection,
  isOpen,
  setIsOpen,
}) => (
  <div className={`${COMMON_STYLES.container} lg:hidden`}>
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="flex items-center justify-between w-full p-4 text-left"
    >
      <h2 className={COMMON_STYLES.heading}>Table of Contents</h2>
      <motion.div
        animate={{ rotate: isOpen ? 180 : 0 }}
        transition={ANIMATIONS.chevron.transition}
      >
        <ChevronDown className="w-6 h-6" />
      </motion.div>
    </button>

    <AnimatePresence>
      {isOpen && (
        <motion.div {...ANIMATIONS.accordion} className="overflow-hidden">
          <ul className={COMMON_STYLES.list}>
            {headings.map((item) => (
              <HeadingItem
                key={item.hashLink}
                item={item}
                isActive={activeSection === item.hashLink}
                onClick={() => {
                  scrollToSection(item.hashLink);
                }}
              />
            ))}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  </div>
);

AccordionVersion.propTypes = {
  headings: headingsPropType,
  activeSection: PropTypes.string,
  scrollToSection: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const DesktopVersion = ({ headings, activeSection, scrollToSection }) => (
  <div className={`hidden ${COMMON_STYLES.container} lg:block`}>
    <div className={COMMON_STYLES.headingWrapper}>
      <h1 className={COMMON_STYLES.heading}>Table of Contents</h1>
    </div>
    <div>
      <ul className={COMMON_STYLES.list}>
        {headings.map((item) => (
          <HeadingItem
            key={item.hashLink}
            item={item}
            isActive={activeSection === item.hashLink}
            onClick={() => scrollToSection(item.hashLink)}
          />
        ))}
      </ul>
    </div>
  </div>
);

DesktopVersion.propTypes = {
  headings: headingsPropType,
  activeSection: PropTypes.string,
  scrollToSection: PropTypes.func.isRequired,
};

const TableOfContent = ({ tocHeadings }) => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollToSection = useScrollToSection();
  const activeSection = useActiveSection(tocHeadings);

  if (tocHeadings.length <= 0) {
    return null;
  }

  const sharedProps = {
    headings: tocHeadings,
    activeSection,
    scrollToSection,
  };

  return (
    <>
      <AccordionVersion
        {...sharedProps}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <DesktopVersion {...sharedProps} />
    </>
  );
};

TableOfContent.propTypes = {
  tocHeadings: headingsPropType,
};

export default TableOfContent;
