import { useEffect, useState, useRef } from 'react';

export function useActiveSection(headings) {
  const [activeSection, setActiveSection] = useState('');
  const observerRef = useRef(null);
  const sectionsInViewRef = useRef(new Set());
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!headings || headings.length === 0) return;

    const cleanHashLink = (hashLink) => {
      return hashLink.replace(/^#/, '');
    };

    const sectionIds = headings.map((heading) =>
      cleanHashLink(heading.hashLink),
    );

    const updateActiveSection = () => {
      // Clear any pending timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        const visibleSections = Array.from(sectionsInViewRef.current);

        if (visibleSections.length === 0) return;

        const firstVisibleSection = sectionIds.find((hashLink) =>
          visibleSections.includes(hashLink.replace('#', '')),
        );

        if (firstVisibleSection && firstVisibleSection !== activeSection) {
          setActiveSection(firstVisibleSection);
        }
      }, 100);
    };

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.id;

          if (entry.isIntersecting) {
            sectionsInViewRef.current.add(id);
          } else {
            sectionsInViewRef.current.delete(id);
          }
        });

        updateActiveSection();
      },
      {
        rootMargin: '-10% 0px -10% 0px',
        threshold: [0.1],
      },
    );

    // Start observing sections
    sectionIds.forEach((hashLink) => {
      const id = hashLink.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        observerRef.current.observe(element);
      }
    });

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      sectionsInViewRef.current.clear();
    };
  }, [headings, activeSection]);

  return activeSection;
}
