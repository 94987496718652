import React, { useEffect, useState } from 'react';

const BackToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className={`fixed z-10 right-5 bottom-5 sm:right-10 lg:right-24 sm:bottom-10 hover:shadow bg-neutral border border-neutral-7 p-2 rounded-full transition-all duration-300  ${
        visible ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      onClick={scrollToTop}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 9.99999L10.5858 6.41421C11.2525 5.74754 11.5858 5.41421 12 5.41421C12.4142 5.41421 12.7475 5.74754 13.4142 6.41421L17 9.99999"
          stroke="#383E42"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12 18L12 6"
          stroke="#383E42"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export default BackToTop;
