import PropTypes from 'prop-types';
import React from 'react';
import { motion } from 'framer-motion';
import { useToast } from '../hooks/useToast';

const socialLinks = [
  {
    icon: 'facebook-color-icon',
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    icon: 'x-color-icon',
    url: 'https://twitter.com/intent/tweet?text=@greentechapps&url=',
  },
  {
    icon: 'linkedin-color-icon',
    url: 'https://www.linkedin.com/shareArticle?url=',
  },
  {
    icon: 'whatsapp-color-icon',
    url: 'whatsapp://send?text=',
  },
  {
    icon: 'telegram-color-icon',
    url: 'https://telegram.me/share/url?url=',
  },
];

const BlogSharing = ({ canonicalUrl }) => {
  const { addToast } = useToast();

  const handleCopyLink = async () => {
    if (typeof window !== 'undefined') {
      try {
        await navigator.clipboard.writeText(canonicalUrl);
        addToast('Link copied to clipboard!', 'success');
      } catch (err) {
        addToast('Failed to copy link', 'error');
      }
    }
  };

  return (
    <div className="flex gap-4 mx-2 md:my-6">
      {socialLinks.map((link) => (
        <a
          key={link.url}
          href={link.url + canonicalUrl}
          target="_blank"
          rel="noreferrer"
        >
          <motion.div className="w-8" whileHover={{ scale: 1.1 }}>
            <img
              className="w-full"
              src={`/icons/${link.icon}.svg`}
              alt="Logo"
            />
          </motion.div>
        </a>
      ))}
      <motion.button
        onClick={handleCopyLink}
        className="w-8"
        whileHover={{ scale: 1.1 }}
        aria-label="Copy link to clipboard"
      >
        <img
          className="w-full"
          src="/icons/share-link-color-icon.svg"
          alt="Copy link"
        />
      </motion.button>
    </div>
  );
};

BlogSharing.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
};

export default BlogSharing;
